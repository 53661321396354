import AxiosProvider from "./AxiosProvider";
import AxiosProvider2 from "./AxiosProvider2";

export const menus = (data) => {
  return AxiosProvider.get("/mdiaf/menus", data);
};

export const singleMenu = (id) => {
  return AxiosProvider.get(`/mdiaf/menus/${id}`);
};

export const banner = () => {
  return AxiosProvider.get("/banners", {
    params: {
      type: "website",
    },
  });
};
export const services = (data) => {
  return AxiosProvider.get("mdiaf/services", data);
};
export const images = (data) => {
  return AxiosProvider.get("mdiaf/images", data);
};

export const phones = (data) => {
  return AxiosProvider.get("infos/mdiaf_phones", data);
};

export const inquire = (data) => {
  return AxiosProvider.get("mdiaf/categories/", data);
};

export const countries = (data) => {
  return AxiosProvider.get("countries", data);
};

export const login = (values) => {
  return AxiosProvider.post("customers/get-token", values, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const SMS = (headers) => {
  return AxiosProvider.get("customers/phone-verification-SMS-send", {
    headers,
  });
};

export const CustomerInfo = (token) => {
  return AxiosProvider.get("customers/account-info", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    
    },
  });
};

export const payment = (id, token) => {
  return AxiosProvider.get("/orders/tap/payment-status/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const makeAccept = (orderId, token, data) => {
  return AxiosProvider.put(`/orders/${orderId}/make-accepted`, data,{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const CustomerInfo2 = (token) => {
  return AxiosProvider.get("customers/account-info", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const account = (values) => {
  return AxiosProvider.post("customers", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const contact = (values) => {
  return AxiosProvider.post("/mdiaf/contact-us", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const calories = () => {
  return AxiosProvider2.get("/meals/allergens", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const terms = (values) => {
  return AxiosProvider.get("infos/terms", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const otp = (values, token) => {
  return AxiosProvider.post("customers/verify-user", values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const usercode = (values) => {
  let params = {
    type: "OTP",
  };
  return AxiosProvider.post("customers/verify-user", values, params, {
    headers: {
      "Content-Type": "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const resendCode = (params) => {
  return AxiosProvider.get("customers/OTP-SMS-send", {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const checkPhone = (params) => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    applicationId: 1,
    platform: "web",
  };

  return AxiosProvider.get("customers/check-phone", { params, headers });
};

export const forgetOTP = (data) => {
  let headers = {
    "Content-Type": "application/json",
    applicationId: 1,
    platform: "web",
  };
  let params = {
    type: "OTP",
  };

  return AxiosProvider.post("customers/get-token", data, {
    params,
    headers,
  });
};

export const sendBasket = (values, token) => {
  return AxiosProvider.post("mdiaf/inquires", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const changePassword = (values, token) => {
  return AxiosProvider.put("customers", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const changePassCustomer = (token) => {
  return AxiosProvider.get("customers/account-info", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const updateCustomer = (values, token) => {
  return AxiosProvider.put("customers", values, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const mediafOrder = (token) => {
  return AxiosProvider.get("mdiaf-orders", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const book = (values, token) => {
  return AxiosProvider.post("mdiaf-orders", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const singleOrder = (id, token) => {
  return AxiosProvider.get("mdiaf-orders/" + id, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const cancelOrder = (id, token) => {
  let type = {
    type: "canceled",
  };
  return AxiosProvider.put("mdiaf-orders/" + id + "/cancel", type, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const payAgain = (id, token) => {
  return AxiosProvider.get("mdiaf-orders/" + id + "/payment-url", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const Banners = (type, language, countryId) => {
  return AxiosProvider.get("banners", {
    params: {
      type: type,
      language: language,
      countryId: countryId,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const clients = () => {
  return AxiosProvider.get("sales/clients", {
    headers: {
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const salesSingleMenu = (id) => {
  return AxiosProvider.get("sales/menus/" + id, {
    headers: {
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const salesMenu = (id) => {
  return AxiosProvider.get("sales/menus", {
    headers: {
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const salesService = () => {
  return AxiosProvider.get("sales/services", {
    headers: {
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const checkLoyalty = (token) => {
  return AxiosProvider.get("customers/check-loyalty", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const points = (token) => {
  return AxiosProvider.get("points", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const awafi_register = (values, token) => {
  return AxiosProvider.post("customers/loyalty", values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const awafi_code = (values) => {
  return AxiosProvider.post("customers/awafi-code-issue", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const branches = (type) => {
  return AxiosProvider.get("/branches", {
    params: {
      type: type,
      applicationId: 1,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const branchByCountry = (countryId) => {
  return AxiosProvider.get("/branches", {
    params: {
      type: "all",
      countryId: countryId,
      applicationId: 1,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const branchesNear = (type, lat, lng) => {
  return AxiosProvider.get("/branches", {
    params: {
      type: type,
      latitude: lat,
      longitude: lng,
      applicationId: 1,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const address = (values, token, countryId) => {
  return AxiosProvider.post("addresses", values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      countryId: countryId,

      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const saved_address = (token, countryId) => {
  return AxiosProvider.get("addresses", {
    headers: {
      Authorization: `Bearer ${token}`,
      countryId: countryId,

      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const categories = (data, language) => {
  const queryParams = {
    params: {
      applicationId: 1,
    },
    headers:{
      'language':language,
      applicationId: 1,
      platform: "web",
    }
  };

  const branchId = localStorage.getItem("branchId")
  queryParams.params.branchId = branchId;

  return AxiosProvider.get("/categories", {
    ...queryParams, 
    ...data
    
  });
};

export const products = (id, branchId, language) => {
  return AxiosProvider.get("/products", {
    params: {
      categoryId: id,
      branchId: branchId,
      categoryMessage: "true",
      categoryRecommended: "true",
      applicationId: 1,
    },
    headers:{
      'language':language,
      applicationId: 1,
      platform: "web",
    }
  });
};

export const single_product = (id, branchId, token, language) => {
  return AxiosProvider2.get(`/products/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,

      Accept: "application/json",
    },
    params: {
      branchId: branchId,
      applicationId: 1,
    },
    headers:{
      'language':language,
      applicationId: 1,
      platform: "web",
    }
    
  });
};

export const Add_to_cart = (data, token) => {
  var body = {
    branchId: data.branchId,
    typeId: data.typeId,
    addressId: data.addressId,
    platform: "web",
    appVersion: data.appVersion,
    productId: data.productId,
    quantity: data.quantity,
    sizeId: data.sizeId,
    additionalId: data.additionalId,
    extras: data.extras,
  };
  return AxiosProvider.post(`/cart`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
    params: {
      applicationId: 1,
    },
  });
};

export const Get_Basket = (token) => {
  return AxiosProvider.get(`/cart`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
    params: {
      applicationId: 1,
    },
  });
};

export const Edit_Quantity = (cartId, mealId, quantity, token) => {
  var body = {
    quantity: quantity,
  };
  return AxiosProvider.put(`/cart/${cartId}/meal/${mealId}/quantity`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const Delete_Item = (cartId, id, token) => {
  return AxiosProvider.delete(`/cart/${cartId}/meal/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const Get_Wallet = (token, countryId) => {
  return AxiosProvider.get(`/customers/wallet-balance`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      countryId: countryId,
      applicationId: "1",
      platform: "web",
    },
  });
};

export const Get_Subscriptions = (language, countryId, token) => {
  console.log(`sent country id ${countryId}`);
  return AxiosProvider.get(`/subscriptions`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      countryId: countryId,
      language: language,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const terms_packages = () => {
  return AxiosProvider.get(`/infos/terms?type=subscriptions`);
};

export const Subscription_package = (language, countryId, token, data) => {
  return AxiosProvider.post(`/subscriptions`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      countryId: countryId,
      language: language,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const charge_wallet = (token, data, countryId) => {
  return AxiosProvider.post(`wallets`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      countryId: countryId,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const pointExchange = (userPoint, token) => {
  let data = {
    points: userPoint,
  };

  return AxiosProvider.post("points/change-points", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const orders = (token) => {
  return AxiosProvider.get(`/orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const near_branches = (lat, lng, classification) => {
  return AxiosProvider.get("/branches", {
    params: {
      type: "nearby",
      class:classification,

      latitude: lat,
      longitude: lng,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const Clear_cart = (cartId, token) => {
  return AxiosProvider.delete(`/cart/${cartId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const branchesByCity = (id, countryId) => {
  return AxiosProvider.get("/branches", {
    params: {
      type: "city",
      class: "branch",
      cityId: id,
      countryId: countryId,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const carBranches = (lat, lng) => {
  return AxiosProvider.get("/branches", {
    params: {
      type: "nearby",
      class: "car",
      latitude: lat,
      longitude: lng,
      applicationId: 1,
      platform: "web",
    },
  });
};
export const carBranches_city = (id) => {
  return AxiosProvider.get("/branches", {
    params: {
      type: "city",
      class: "car",
      cityId: id,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const awafi_verify = (values) => {
  return AxiosProvider.post("customers/loyalty-by-phone", values, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const delete_address = (id, token) => {
  return AxiosProvider.delete(`addresses/` + id, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const payment_methods = (cartId, delivery, token) => {
  return AxiosProvider.get(`/cart/${cartId}/payment-methods`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    params: {
      deliveryType: delivery,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const check_offer = (body, token) => {
  return AxiosProvider.post(`/cart/offer`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
      
    },
  });
};
export const add_address_car = (orderId , body, token) => {
  console.log(body)
  return AxiosProvider.put(`/orders/${orderId}/add_latlng`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const terms_conditions = () => {
  return AxiosProvider.get(`/infos/terms`, {
    headers: {
     
      Accept: "application/json",
    },
  });
};

export const payAgain_checkout = (token, paymentToken, orderId) => {
  var body = {
    token: paymentToken,
    applicationId: 1,
  };
  return AxiosProvider.post(`/orders/${orderId}/repay`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const create_order = (data, token) => {
  return AxiosProvider.post(`/cart/order`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
    params: {
      applicationId: 1,
    },
  });
};

export const order_track = (id, token) => {
  return AxiosProvider.get("orders/" + id, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const editPicture = (photo, token) => {
  let body = {
    photo,
    _method: "PUT",
  };
  return AxiosProvider.post("customers/edit-photo", photo, {
    headers: {
      Authorization: "Bearer " + token,

      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const pay_again = (orderId, token) => {
  return AxiosProvider.get("orders/" + orderId + "/payment-url", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const cancel_request = (message, token) => {
  return AxiosProvider.post("chats", message, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const cancel_direct = (id, token) => {
  let body = {
    type: "canceled",
  };
  return AxiosProvider.put(`orders/${id}`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
  });
};

export const publications = () => {
  return AxiosProvider.get("/blog", {
    params: {
      type: "magazine",
    },
  });
};

export const medias = () => {
  return AxiosProvider.get("/blog", {
    params: {
      type: "media",
    },
  });
};
export const mediaPost = (postId) => {
  return AxiosProvider.get(`/blog/${postId}`, {
    params: {
      type: "media",
    },
  });
};
export const inquireMenu = (values, token) => {
  return AxiosProvider.post("sales/inquires", values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const verifyPhone = (values, token) => {
  return AxiosProvider.put("customers/edit-phone", values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const getUserByPhone = (data, token) => {
  return AxiosProvider2.post("users/getByPhone", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const sendMoneyToUser = (data, token) => {
  return AxiosProvider2.post("wallets/transfer", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const repeat_order = (token, orderId) => {
  return AxiosProvider.post(
    `orders/${orderId}/repeat`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        applicationId: 1,
        platform: "web",
      },
    }
  );
};

export const invoiceData = (id, code) => {
  return AxiosProvider.get(`/orders/invoice/${id}/${code}`);
};

export const recommend = (token, branchId) => {
  return AxiosProvider.get("recommendedProducts/", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      applicationId: 1,
      platform: "web",
    },
    params: {
      branchId: branchId,
      applicationId: 1,
    },
  });
};
export const checkPaymentToken = (token, sessionId, currency, type) => {
  return AxiosProvider.get(
    `/orders/checkout/get-paymentlink-details?paymentLinkId=${sessionId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        applicationId: 1,
        platform: "web",

        countryId: currency == "SAR" ? 1 : 2,
      },
      params: {
        type: type,
        currency: currency,
        platform: "web",
      },
    }
  );
};

export const checkPayment = (sessionId, currency, type, orderId) => {
  return AxiosProvider.get(
    `/orders/website/get-paymentlink-details?paymentLinkId=${sessionId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        countryId: currency == "SAR" ? 1 : 2,
        applicationId: 1,
        platform: "web",
      },
      params: {
        type: type,
        currency: currency,
        orderId: orderId,
        platform: "web",
      },
    }
  );
};
export const invoice = (tapId) => {
  return AxiosProvider.get("orders/tap/invoice-status/" + tapId, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const getOrganizations = (token) => {
  return AxiosProvider.get("user-organizations", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,

      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const addOrganization = (token, data) => {
  return AxiosProvider.post("user-organizations", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,

      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};
export const setDefault = (token, data) => {
  return AxiosProvider.post("customers/add-default-invoice", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,

      Accept: "application/json",
      applicationId: 1,
      platform: "web",
    },
  });
};

export const getTypes = () => {
  return AxiosProvider.get("/types",  {
    headers: {
      "Content-Type": "application/json",
      "platform": "web",
      applicationId: 1,
      platform: "web",
    }
  })
}