import "../Styles/UserProfile.css";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import AddressCard from "../Components/AddressCard";
import { IoAddCircleOutline } from "react-icons/io5";
import Autocomplete from "react-google-autocomplete";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMemo, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import homeLocation from "../Images/homeLocation.svg";
import countryList from "react-select-country-list";
import { address, branchesNear } from "../api";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import SuccessModal from "../Components/SuccessModal";
import { BsSearch } from "react-icons/bs";
function ProfileAddAddress(props) {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${googleApiKey}`,

    libraries: ["places"],
  });
  const [modal, setModal] = useState(false);
  const [position, setPostion] = useState({
    lat: props.loc.lat,
    lng: props.loc.lng,
  });
  const [cntry, setCntry] = useState(props.countryCode);
  const [outside, setOutside] = useState(false);
  const history = useNavigate();

  //API that reverses lat and lng to english and arabic names of the location
  //uses geocode to 1- set current address 2- check if in bahrain 3- check if outside saudi/bahrain
  ///if in bahrain (cntry=="BH") more input fields are displayed for user
  //if outside  saudi/bahrain submit button is disabled
  useEffect(() => {
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.lat +
          "," +
          position.lng +
          "&language=ar&result_type=country&region=SA&key="+ googleApiKey
      )
      .then((response) => {
        if (
          response.data.results[0].address_components[0].short_name !== "BH" &&
          response.data.results[0].address_components[0].short_name !== "SA"
        ) {
          setOutside(true);
        } else {
          setOutside(false);
        }

        setCntry(response.data.results[0].address_components[0].short_name);
      });

    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.lat +
          "," +
          position.lng +
          "&language=ar&region=SA&key="+ googleApiKey
      )
      .then((response) => {
        setArAddress(response.data.results[0].formatted_address);
      });
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          position.lat +
          "," +
          position.lng +
          "&language=en&region=SA&key="+ googleApiKey
      )
      .then((response) => {
        setEnAddress(response.data.results[0].formatted_address);
      });
  }, []);

  const Schema = yup.object().shape({
    name: yup.string().required("validation.title_required"),
    place: yup.string().required("validation.place_required"),
    compound: yup.string().required("validation.compound_required"),
    street: yup.string().required("validation.street_required"),

    building: yup.string().required("validation.building_required"),

    apartment: yup.string().required("validation.apartment_required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      place: "",
      compound: "",
      street: "",
      building: "",
      apartment: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const nameRef = useRef();
  const notesRef = useRef();
  const compoundRef = useRef();
  const streetRef = useRef();
  const buildingRef = useRef();
  const apartmentRef = useRef();

  const options = useMemo(() => countryList().getData(), []);
  const [showingAlert, setShowingAlert] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  //   const [empty,setEmpty]=useState(false);
  //const [empty2,setEmpty2]=useState(false);

  //  const [value, setValue] = useState('')
  const { t, i18n } = useTranslation();
  //  const [map,setMap]=useState(null);
  const [arAddress, setArAddress] = useState(null);
  const [enAddress, setEnAddress] = useState(null);

  //displays loading if map is not yet loaded
  if (!isLoaded) {
    return (
      <div className="loader profileLoader">
        <BeatLoader color={"#E47100"} loading={!isLoaded} size={30} />
      </div>
    );
  }

  //API that reverses lat and lng to english and arabic names of the location
  //uses geocode to 1- set current address 2- check if in bahrain 3- check if outside saudi/bahrain
  ///if in bahrain (cntry=="BH") more input fields are displayed for user
  //if outside  saudi/bahrain submit button is disabled
  function handleLocationChange(lat, lng) {
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat +
          "," +
          lng +
          "&language=ar&result_type=country&region=SA&key="+ googleApiKey
      )
      .then((response) => {
        if (
          response.data.results[0].address_components[0].short_name !== "BH" &&
          response.data.results[0].address_components[0].short_name !== "SA"
        ) {
          setOutside(true);
        } else {
          setOutside(false);
        }

        setCntry(response.data.results[0].address_components[0].short_name);
      });

    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat +
          "," +
          lng +
          "&language=ar&region=SA&key="+ googleApiKey
      )
      .then((response) => {
        setArAddress(response.data.results[0].formatted_address);
      });
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat +
          "," +
          lng +
          "&language=en&region=SA&key="+ googleApiKey
      )
      .then((response) => {
        setEnAddress(response.data.results[0].formatted_address);
      });
  }

  //submits values (some values depend on selected country)
  //address api call saves the address then SuccessModal is displayed then ProfileAddAddress component is hidden (  props.setAddAddress(false))

  function onSubmit(e) {
    e.preventDefault();
    setSubmitLoading(true);
    const token = localStorage.getItem("token");
    console.log(nameRef.current.value);
    /*if(!value){
    setEmpty(true)
    setSubmitLoading(false)
  }*/

    let values = {
      arAddress: arAddress,
      enAddress: enAddress,
      latitude: position.lat,
      longitude: position.lng,
      title: nameRef.current.value,
      notes: notesRef.current.value,
      compoundNo: cntry == "BH" ? parseInt(compoundRef.current.value) : "",
      streetNo: cntry == "BH" ? parseInt(streetRef.current.value) : "",
      buildingNo: cntry == "BH" ? parseInt(buildingRef.current.value) : "",
      apartmentNo: cntry == "BH" ? parseInt(apartmentRef.current.value) : "",
    };

    if (token) {
      let countryId = props.country == 0 ? 1 : 2;

      address(values, token, countryId)
        .then((response) => {
          localStorage.setItem("addressId", response.data.data.id);
          localStorage.setItem("addressLat", response.data.data.latitude);
          localStorage.setItem("addressLong", response.data.data.longitude);
          setSubmitLoading(false);
          setModal(true);

          setTimeout(function () {
            props.setAddAddress(false);
            console.log(props.addAddress);
          }, 2000);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            //used to change cart icon's current value to 0

            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          }
          setSubmitLoading(false);
        });
    }
  }

  function handleAutoComplete(place) {
    handleLocationChange(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
    setPostion({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  } /*
  function handleChange(e){
    console.log(e.target.value)
    if(e.target.value){
      setEmpty2(false)
    }
    else{
      setEmpty2(true)
    }
  }
*/

  //on changing marker location
  //marker lat and lng are sent as arguments in handleLocationChange function
  function onMarkerDragEnd(evt) {
    var newLat = evt.latLng.lat();
    var newLng = evt.latLng.lng();
    console.log(newLat);
    handleLocationChange(newLat, newLng);
    setPostion({
      lat: newLat,
      lng: newLng,
    });
  }

  return (
    <>
      {modal ? <SuccessModal setModal={setModal} /> : null}

      <div className="aboutusBody profileBody container-fluid fluid">
        <div className="upAddress">
          <p className="upTable-title"> {t("profile.address")}</p>
        </div>
        <div className="row addressInput userAddAddress">
          <div style={{ textAlign: "center" }}>
            <p className="addressTitle"> {t("profile.add_newAddress")}</p>
          </div>

          <div className="col-lg-6">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="row gy-4">
                <div className="col-12">
                  <label> {t("address.address_title")}</label>
                  <input
                    placeholder={t("address.address_title")}
                    ref={nameRef}
                    value={formik.values.name}
                    id="name"
                    name="name"
                    required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.name && formik.touched.name
                        ? "invalid"
                        : null
                    }
                  />
                  <span>
                    {formik.errors.name && formik.touched.name
                      ? t(`${formik.errors.name}`)
                      : null}
                  </span>
                </div>

                {/*  <div className="col-6">
        <label>{t("address.city")}</label>
        <Select options={options} value={value} onChange={changeHandler} placeholder={<div className="select-placeholder-text">{t("address.city")}</div>}  onBlur={()=>!value? setEmpty(true):null} className={empty?"invalid":null}/>
        <span>{empty?t("validation.city_required"):null}</span>
      </div>
        <div className="col-6">
        <label>{t("address.area")}</label>

      
<Autocomplete
disabled={value?false:true}
                        style={{width: '100%'}}
                        onPlaceSelected={(place) => handleAutoComplete(place)
                        
                        
                        } 
                       
                    options={{
                        types: ["geocode"],
                        componentRestrictions: { country: [value.value]},
                      }}
                        placeholder= {t("address.area_name")}
                        id="place" name="place"  required={true} onChange={(e)=>handleChange(e)} onBlur={(e)=>handleChange(e)}  className={empty2?"invalid":null}
                    />
                            <span>{empty2?t("validation.place_required"):null}</span>
        </div>
     
                    */}
                <div className="col-12 autoComplete">
                  <label>{t("address.area")}</label>

                  <Autocomplete
                    style={{ width: "100%" }}
                    onPlaceSelected={(place) => handleAutoComplete(place)}
                    options={{
                      types: ["geocode"],
                      componentRestrictions: { country: ["sa", "bh"] },
                    }}
                    placeholder={t("address.area_name")}
                    id="place"
                    name="place"
                  />

                  <BsSearch
                    size={27}
                    className={
                      i18n.language == "ar" ? "mapIcn" : " mapIcn mapIcnEn"
                    }
                  />
                </div>

                {cntry == "BH" ? (
                  <>
                    <div className="col-6">
                      <label>{t("address.compound")}</label>
                      <input
                        placeholder={t("address.compoundNo")}
                        type="number"
                        ref={compoundRef}
                        value={formik.values.compound}
                        id="compound"
                        name="compound"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.compound && formik.touched.compound
                            ? "invalid"
                            : null
                        }
                      />
                      <span>
                        {formik.errors.compound && formik.touched.compound
                          ? t(`${formik.errors.compound}`)
                          : null}
                      </span>
                    </div>
                    <div className="col-6">
                      <label>{t("address.street")}</label>
                      <input
                        placeholder={t("address.streetNo")}
                        type="number"
                        ref={streetRef}
                        value={formik.values.street}
                        id="street"
                        name="street"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.street && formik.touched.street
                            ? "invalid"
                            : null
                        }
                      />
                      <span>
                        {formik.errors.street && formik.touched.street
                          ? t(`${formik.errors.street}`)
                          : null}
                      </span>
                    </div>
                    <div className="col-6">
                      <label>{t("address.building")}</label>
                      <input
                        placeholder={t("address.buildingNo")}
                        type="number"
                        ref={buildingRef}
                        value={formik.values.building}
                        id="building"
                        name="building"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.building && formik.touched.building
                            ? "invalid"
                            : null
                        }
                      />
                      <span>
                        {formik.errors.building && formik.touched.building
                          ? t(`${formik.errors.building}`)
                          : null}
                      </span>
                    </div>
                    <div className="col-6">
                      <label>{t("address.apartment")}</label>
                      <input
                        placeholder={t("address.apartmentNo")}
                        type="number"
                        ref={apartmentRef}
                        value={formik.values.apartment}
                        id="apartment"
                        name="apartment"
                        required={true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.apartment && formik.touched.apartment
                            ? "invalid"
                            : null
                        }
                      />
                      <span>
                        {formik.errors.apartment && formik.touched.apartment
                          ? t(`${formik.errors.apartment}`)
                          : null}
                      </span>
                    </div>
                  </>
                ) : null}
                <div className="col-12">
                  <label> {t("address.notes")}</label>
                  <input ref={notesRef} className="addressNotes" />
                </div>
                <div className="col-12">
                  {submitLoading ? (
                    <div
                      style={{ alignItems: "initial", height: "30px" }}
                      className="loader profileLoader"
                    >
                      <BeatLoader
                        color={"#E47100"}
                        loading={submitLoading}
                        size={30}
                      />
                    </div>
                  ) : (
                    <>
                      {outside ? (
                        <button disabled={true} type="submit">
                          {t("address.outside")}
                        </button>
                      ) : (
                        <button
                          disabled={showingAlert ? true : false}
                          type="submit"
                        >
                          {" "}
                          {showingAlert
                            ? t("address.added")
                            : t("address.save_address")}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div
            style={{ margin: "1em 0" }}
            className="col-lg-6 order-first order-lg-last"
          >
            <GoogleMap
              zoom={10}
              center={{ lat: position.lat, lng: position.lng }}
              mapContainerClassName="userMap"
              options={{
                mapTypeControl: false,
              }}
            >
              <Marker
                icon={homeLocation}
                draggable={true}
                position={{ lat: position.lat, lng: position.lng }}
                onDragEnd={(e) => onMarkerDragEnd(e)}
              />
            </GoogleMap>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileAddAddress;
